// Enquanto o redesign não é aplicado a todas as telas, aqui são especificadas as telas com background branco
// Para alterar em todas as telas, deve ser alterada a cor da variável $kt-content-bg-color no _config.scss global

#kt_content:has(div.tela-dashboard),
#kt_content:has(div.tela-dashboard-estrategica),
#kt_content:has(div.widget-ocupacao),
#kt_content:has(div.horarioPico) {
	padding: 0;
}


.filtros {
	margin-bottom: 24px;

	h2.filtros-title {
		font-size: 20px;
		font-weight: 500;
		color: #222222;
	}
}

@media screen and (max-width: 767px) {
	.filtros {
		.filtros-select {
			margin-bottom: 16px;
		}
	}
}

#kt_scrolltop {
	bottom: 64px !important;
	right: 16px !important;
}

// =*=*=*=*= AUTH PAGE =*=*=*=*=

.container-auth-page {
	background-color: #EEF1FF;
	display: flex;
	flex-direction: row;
	flex: 1;
}

.section-auth-page {
	flex: 1;
	background-color: #EEF1FF;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 48px;
	height: 100%;
}

.card-login {
	padding: 40px;
	height: auto;
	background-color: #fff;
	border: 1px solid #CCCCCC;
	border-radius: 16px;
	width: 100%;
	max-width: 600px;
}

.card-login-title {
	font-size: 24px;
	font-family: 'Kumbh Sans';
	font-weight: 600;
	line-height: 32px;
	color: #222222;
	margin-bottom: 12px;
}

.card-login-subtitle {
	font-size: 16px;
	font-family: 'Kumbh Sans';
	font-weight: 400;
	line-height: 24px;
	color: #222222;
	margin-bottom: 12px;
}

.card-login-container-button {
	margin-top: 48px;
}

.card-login-termos-politica {
	margin-top: 48px;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
}

@media (max-width: 1024px) {
	.section-auth-page {
		padding: 40px 20px;
	}

	.container-auth-page {
		flex-direction: column;
	}

	.card-login {
		padding: 20px;
	}

	.card-login-title {
		text-align: center;
	}

	.card-login-subtitle {
		text-align: center;
	}

	.card-login-container-logo {
		display: flex;
		justify-content: center;
	}
}

// =*=*=*=*= COMMON =*=*=*=*=
.btn-v2-primary {
	background-color: #284EEA !important;
	border-radius: 24px !important;
	padding: 12px !important;
	font-family: 'Kumbh Sans' !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: #fff !important;
	width: 100%;
}

.btn-v2-primary:hover {
	background-color: #6f85f7 !important;
}

.btn-v2-primary:active {
	background-color: #284EEA !important;
}

.card-login-container-links {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.forgot-password,
.forgot-password:hover {
	color: #284EEA !important;
	font-family: 'Kumbh Sans' !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	text-decoration-line: underline !important;
	line-height: 24px !important;
	cursor: pointer;
}

// =*=*=*=*= COMPONENTE ButtonV2 =*=*=*=*=

.button-v2 {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-end;
	padding-bottom: 5px;
	min-width: fit-content;

	.btn.btn-v2 {
		width: 100%;
		font-size: 16px;
		height: 48px;
		border-radius: 48px;
		display: flex;
		align-items: center;
		justify-content: center;

	}
	
	.btn-v2-transparent {
		background-color: transparent;
		color: #335BFF;
	}

	.btn-v2-transparent:hover {
		background-color: #E0E0E0;
	}

	.btn-v2-blue {
		background-color: #284EEA !important;
		color: #FFFFFF !important;
	}
}

// =*=*=*=*= COMPONENTE CardV2 =*=*=*=*=

.card-v2 {
	border: 1px solid #CCCCCC;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	padding: 24px;

	*:not(i) {
		font-family: 'Kumbh Sans';
	}

	h4.card-v2-header {
		font-size: 20px;
		font-weight: 500;
		color: #333333;
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;

		a {
			color: #333333;
			cursor: pointer;
		}

		a:hover {
			color: #777777 !important;
		}
	}

	.card-v2-title {
		margin-right: 16px;
	}
}

@media screen and (max-width: 767px) {
	.card-v2 {
		padding: 16px;

		h4.card-v2-header:has(.paginator-v2) {
			flex-direction: column;

			.card-v2-title {
				margin-bottom: 16px;
				display: flex;
				justify-content: space-between;
			}
		}
	}

	.card-v2:has(.card-v2-hold) {
		height: 154px;
	}
}

.card-v2:has(.card-v2-hold) {
	background-color: #333333;
	opacity: 0.5;
	filter: blur(2px);
}

.card-v2-hold-message {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: 500;
	color: #FFFFFF;
	z-index: 1;
	pointer-events: none;
}

// =*=*=*=*= COMPONENTE CarouselV2 =*=*=*=*=

.carousel-v2 {
	height: 210px;
	
	.carousel-v2-item-container img.carousel-v2-item {
		height: 180px;
		width: 100%;
		object-fit: cover;
		border-radius: 8px;
	}
	
	.carousel-indicators {
		bottom: -44px;
		
		li {
			background-color: #284EEA;
			height: 10px;
			border-radius: 5px;
			border: none;
			width: 24px;
		}
		
		li.active {
			width: 40px;
		}
	}
	
	@media screen and (max-width: 767px) {
		height: 246px;
		
		.carousel-v2-item-container img.carousel-v2-item {
			height: 216px;
		}
	}
}


// =*=*=*=*= COMPONENTE CarouselV2 =*=*=*=*=

.empty-v2 {
	p {
		color: #333333;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 24px;
	}
}

// =*=*=*=*= COMPONENTE PaginatorV2 =*=*=*=*=

.paginator-v2 {
	border: 1px solid #CCCCCC;
	border-radius: 6px;
	height: 36px;
	display: flex;
	align-items: center;

	.paginator-v2-label {
		font-size: 14px;
		padding: 8px 12px;
		border-right: 1px solid #CCCCCC;
		min-width: max-content;
		display: flex;
		
		.paginator-v2-label-itens {
			color: #333333;
			padding-right: 4px;
			font-weight: 500;
		}
		
		.paginator-v2-label-total {
			color: #666666;
			font-weight: 400;
		}
	}

	.paginator-v2-buttons {
		color: #132366;
		font-size: 14px;
		width: 74px;
		display: flex;
		justify-content: space-evenly;

		i {
			cursor: pointer;
		}

		i.disabled {
			color: #CCCCCC;
			cursor: default;
		}
	}
}

@media screen and (max-width: 767px) {
	.paginator-v2 {
		.paginator-v2-label {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
}

// =*=*=*=*= COMPONENTE SelectV2 =*=*=*=*=

.select-v2 {
	*:not(i) {
		font-family: 'Kumbh Sans';
	}

	*:not(select) {
		border: none;
	}

	.select-v2-label {
		margin-bottom: 4px;
		color: #666666;
		font-size: 14px;
		font-weight: 500;
	}

	.select-v2-form-control {
		height: 48px;
		margin-block: 4px;
		border: 1px solid #CCCCCC;
		border-radius: 6px;
	}
}

// =*=*=*=*= COMPONENTE SimpleTableV2 =*=*=*=*=

.simpletable-v2 {
	.simpletable-v2-item {
		display: flex;
		justify-content: space-between;
		padding-block: 16px;
		align-items: center;

		.simpletable-v2-item-label,
		.simpletable-v2-value {
			font-size: 16px;
			color: #000000;
			font-weight: 500;
			margin-right: 16px;
		}

		.simpletable-v2-item-value {
			display: flex;
			align-items: center;

			.simpletable-v2-value-list {
				display: flex;
				flex-wrap: wrap;
				justify-content: end;
			}
		}

		.simpletable-v2-whatsapp-button button {
			display: flex;
			align-self: center;
			background-color: transparent;
			padding: 0;
			margin-left: 8px;

			i {
				background-color: #16CE06;
				color: #FFF;
				width: 20px;
				height: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-content: center;
				font-size: 12px;
				border-radius: 16px;
			}
		}
	}

	.simpletable-v2-item:not(:last-child) {
		border-bottom: 1px solid #E4E4E4;
	}

	.simpletable-v2-value-list {
		display: flex;

		* {
			margin-inline: 1px;
		}
	}

	.simpletable-v2-value-list>div,
	.simpletable-v2-value-list .simpletable-v2-value-block {
		font-size: 14px;
		font-weight: 500;
		color: #666666;
		display: flex;
		flex-wrap: wrap;
		justify-content: end;
	}

	.simpletable-v2-no-content {
		margin: 50px 60px 50px 60px;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		* {
			color: #666666;
		}
	}

	.simpletable-v2-no-content>i {
		font-size: 5rem !important;
		margin: 0px 0px 40px 0px !important;
	}

	.simpletable-v2-no-content>h3 {
		font-size: 2.3rem !important;
		font-weight: 600 !important;
	}

	.simpletable-v2-no-content>p {
		margin-top: 10px !important;
		font-size: 1.2rem !important;
		font-weight: 300 !important;
	}

	.simpletable-v2-value-item:not(.simpletable-v2-value-block):not(:last-child)::after {
		content: "|";
		margin-left: 4px;
	}
}

@media screen and (min-width: 768px) {
	.simpletable-v2-value-item.simpletable-v2-value-block:not(:last-child)::after {
		content: "|";
		margin-left: 4px;
	}
}

@media screen and (max-width: 767px) {
	.simpletable-v2 {
		padding-inline: 4px;

		.simpletable-v2-value-list {
			justify-content: start !important;
		}

		.simpletable-v2-item:has(.simpletable-v2-value-list) {
			display: block;

			.simpletavle-v2-block {
				display: block;
			}

			.simpletable-v2-item-label {
				margin-bottom: 8px;
			}

			.simpletable-v2-value-block {
				display: flex;
				margin-bottom: 8px;
				justify-content: start;
			}
		}
	}
}


// =*=*=*=*= COMPONENTE DashboardDataV2 =*=*=*=*=

.dashboard-data-v2 {
	.dashboard-data-v2-value {
		display: flex;
		color: #000000;
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 4px;
	}

	.dashboard-data-v2-label {
		color: #666666;
		font-size: 16px;
		font-weight: 500;
		padding-right: 8px;
		margin-bottom: 0px;
	}

	.dashboard-data-v2-value-detail {
		font-size: 14px;
		color: #999999;
		align-self: flex-end;
		padding: 4px;
	}

	.indicator {
		height: 20px;
		width: 20px;
		background-color: transparent;
		border: 1px solid;
		border-radius: 12px;
		display: flex;
		align-items: center;
		margin-block: auto;
		margin-left: 8px;
		padding: 2px;

		svg {
			stroke-width: 2;
		}
	}

	.indicator.positive {
		border-color: #43A047;

		svg {
			stroke: #43A047;
		}
	}

	.indicator.negative {
		border-color: #961F1F;

		svg {
			stroke: #961F1F;
		}
	}
}

.dashboard-data-v2.with-link:hover {
	.dashboard-data-v2-value {
		color: #777777;
	}
}

// =*=*=*=*= COMPONENTE AlertV2 =*=*=*=*=
.alert-v2 {
	border: 1px solid;
	width: 100%;
	border-radius: 8px;
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000000;
	font-size: 24px;
	font-weight: 400;
	
	a {
		color: #3058FF;
	}

	.alert-v2-content {
		text-wrap: stable;
	}
}

.alert-v2.blue {
	background-color: #B8C6FF66;
	border-color: #284EEA;

	svg {
		stroke: #284EEA;
	}
}