#kt_footer {
	padding-block: 29px !important;
	min-height: 80px;
	height: fit-content;

	*:not(i) {
		font-family: 'Kumbh Sans';
	}

	div.kt-container {
		padding-inline: 24px !important;
	}

	div.footer-container {
		display: flex;
		justify-content: space-between;
		width: 100%;

		div {
			align-items: center;
		}

		div.footer-copyright,
		div.footer-copyright>a {
			color: #555555;
			font-weight: 400;
			font-size: 14px;
		}

		div.footer-links {
			display: flex;
			flex-wrap: wrap;
			margin-left: 4px;

			a {
				color: #335BFF;
				font-size: 14px;
			}

			a:not(:last-child) {
				margin-right: 24px;
			}
		}
	}

	a:hover {
		color: #1E3699 !important;
	}

	@media screen and (max-width: 767px) {
		border-top: 1px solid #CCCCCC;
		padding-block: 16px !important;

		div.footer-container {
			display: block;

			.footer-copyright {
				margin-bottom: 10px;
			}

			div.footer-links {
				margin: 0;
			}

			div.footer-links>a {
				width: 40%;
				align-self: flex-start;
			}

			div.footer-links>a:not(:last-child) {
				margin: 0px 0px 16px 0px !important;
			}
		}
	}
}